/**
 * Fix fonts that render as bold in Firefox
 *
 * Put this near the top of your style.css
 * Before any overriding styles
 */
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

/* apply a natural box layout model to all elements, but allowing components to change
   http://www.paulirish.com/2012/box-sizing-border-box-ftw */
:root {
  --b2chat-blue: #4c33ff;
  --b2chat-red: #f27468;
  --b2chat-gray: #e5e5e5;
  --b2chat-gray-100: #323444;
  --b2chat-gray-300: #d4d4d8;
  --b2chat-gray-400: #a1a1aa;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #323444;
}

::selection,
input::selection {
  color: inherit;
  background: #b7adff;
}

.ui {
  font-family: 'Poppins', sans-serif !important;
}
html,
body {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}
button,
input,
optgroup,
select,
textarea {
  font-family: 'Poppins', sans-serif;
}

a,
a:visited,
a:link {
  color: var(--b2chat-blue);
  text-decoration: underline;
}

a:link {
  color: var(--b2chat-blue);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: var(--b2chat-blue);
}

a.MuiButton-root {
  text-decoration: none;
  color: #fff;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  scrollbar-color: #e4e4e7 transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #e4e4e7;
}

/* Set full height: http://stackoverflow.com/questions/6654958/make-body-have-100-of-the-browser-height */
html {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  line-height: unset;
}

#root {
  height: 100%;
}

#root-wrapper {
  height: 100%;
  width: 100%;
}

.float-row-reset {
  clear: both;
}

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
  *zoom: 1;
}

.control-label {
  padding: 0px 10px 0px 0px;
  font-size: 1em;
  font-weight: bold;
}

.error-label {
  color: #f44336;
  font-size: 0.75em;
}

select {
  font-size: 0.95em;
}

span[type='button'] {
  -webkit-appearance: none;
}

.muiSelectField {
  font-size: 0.9rem !important;
}

.muiTextField {
  font-size: 0.9rem !important;
}

.muiMenuItem {
  font-size: 0.75em !important;
}

.muiTabTitle {
  height: 3em !important;
  font-size: 12px !important;
  color: #18181b !important;
}

.muiListItem p {
  font-size: 12px !important;
}

.muiButton {
  height: 22px !important;
}

.muiButton button {
  height: 22px !important;
}

.muiButton span {
  top: -7px !important;
}

.muiButton button div {
  height: 24px !important;
}

.muiSnackbar span {
  color: #ffffff !important;
}

.muiButtonLarge {
  height: 28px !important;
}

.muiButtonLarge button {
  height: 28px !important;
}

.muiButtonLarge span {
  top: -5px !important;
}

.muiButtonLarge button div {
  height: 32px !important;
}

a {
  color: #64b5f6;
}

h3 {
  color: #9e9e9e;
}

i {
  font-style: italic;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #e0e0e0;
  zoom: 1;
  filter: alpha(opacity=40);
  opacity: 0.4;
  z-index: 100;
  color: #616161;
}

.scrollingLandmark {
  visibility: hidden;
  width: 0px;
  height: 0px;
}

.ScriptBox {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(158, 158, 158);
  -moz-border-radius: 0.5em;
  -webkit-border-radius: 0.5em;
  border-radius: 0.5em;
  padding: 0.3em 0.5em 0.5em 0.5em;
  margin: 1em 0.5em 0em 0.5em;
  overflow-x: scroll;
  white-space: pre-wrap;
  user-select: all;
  background-color: white;
  hyphens: auto;
}

.gutter-horizontal {
  position: relative;
  background-color: transparent;
}
.gutter-horizontal {
  cursor: ew-resize;
}
.gutter-horizontal:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  right: 0;
  border-radius: 5px;
  transition: 0.2s ease background-color;
  background-color: transparent;
  opacity: 0.8;
}
.gutter-horizontal:hover:after {
  background-color: #2185d0;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #64b5f6;
  text-decoration: underline;
  cursor: pointer;
}

.act-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes act-loader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.act-loader::after,
.act-loader::before {
  animation: act-loader 2s linear infinite;
  content: '';
  border: 5px solid #4c33ff;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
}

.act-loader::after {
  opacity: 0;
  animation-delay: 1s;
}

/* Login, forgot-password, signup, user-activation, and change-password page */

.container-1 {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.container-2 {
  padding: 20px 90px;
  flex: 1 0 500px;
  min-height: 100vh;
}

.publicity {
  flex: 1 0 750px;
}

.container-3 {
  display: flex;
  flex-direction: column;
  padding: 60px 0 0;
}

.container-3-body {
  display: flex;
  flex-direction: column;
}

.container-3-welcome {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
}

.container-3-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container-3-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin: 5px 0;
}

.form-control {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.demo {
  display: flex;
  align-items: baseline;
  margin-top: 24px;
  gap: 7px;
  justify-content: center;
}

.vector {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vector .demo-link {
  font-weight: 500;
  color: var(--b2chat-blue);
  text-decoration: none;
}

@media only screen and (min-width: 1537px) {
  .container-2 {
    padding: 50px 90px;
  }

  .container-3 {
    padding: 130px 0 0;
  }
}

@media only screen and (max-width: 1259px) {
  .container-1 {
    justify-content: center;
  }

  .container-2 {
    flex: none;
  }

  .container-3 {
    width: 370px;
  }
}

.header-menu-notification {
  display: flex;
  align-items: center;
  gap: 5px;
}

/*End login, forgot-password and change-password page */
