.LogDisplay {
  position: fixed;
  font-size: 1rem;
  width: 65em;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 2px 3px 1px;
  border-color: #dd8e92;
  z-index: 9999999;
}

.LogDisplay .LogTitleBar {
  font-size: 0.8em;
  font-weight: bold;
  color: #ffffff;
  height: 2em;
  padding: 0.25em 0em 0.2em 0.2em;
  margin: 0em 0em 0em 0em;
  background-color: #dd2e2e;
  cursor: pointer;
}

.LogDisplay .LogContents {
  overflow: auto;
  height: 30em;
  font-size: 0.75em;
  line-height: 1.25em;
  padding: 0.25em 0em 0em 0.2em;
  background-color: #333355;
  color: #ffffff;
}
